<template>
  <a-form class="findpwd ng-form" :form="form" hideRequiredMark @submit="handleSubmit">
    <h2 class="title">{{$t('忘记密码')}}</h2>
    <div class="register-line"></div>

    <a-form-item label="账号" :required="false">
      <a-input
        class="sub-input"
        size="large"
        placeholder="请输入手机号/邮箱"
        v-decorator="[ 'phoneOrEmail', {
          rules: [$validate.requiredFunc($t('template.sthNotEmpty', { name: $t('template.phoneEmail') })), $validate.phoneOrEmail], 
        }]"
      />
    </a-form-item>

    <a-form-item style="display: none;">
      <a-input class="sub-input" size="large" v-decorator="[ 'phone_area', {initialValue: '86'}]" />
    </a-form-item>

    <a-form-item style="display: none;">
      <a-input v-decorator="[ 'phone_code', {initialValue: '1000'}]" />
    </a-form-item>

    <a-form-item label="验证码" class="m-bottom-0 connect-form-item">
      <a-form-item style="display: inline-block; width: calc(100% - 106px); margin-right: 12px;">
        <a-input
          class="sub-input"
          size="large"
          v-decorator="['verify_code', { 
            rules: [$validate.requiredFunc($t('template.sthNotEmpty', {name: $t('template.code')}))], 
            initialValue: '', 
          }]"
          :placeholder="$t('template.typeSth', {name: $t('template.code')})"
        />
      </a-form-item>

      <div :style="{display: 'inline-block', width: '94px'}">
        <form-send-code-button
          class="sen-code-btn"
          type="sub"
          size="large"
          :url-func="codeRequestFunc"
          @click="onClickSendCode"
          @sendSuccess="onSendSuccess"
          @countDownFinished="onCountDownFinished"
        ></form-send-code-button>
      </div>
    </a-form-item>

    <!-- <div class="item-text">密码</div> -->

    <a-form-item :required="false" label="密码">
      <a-input-password
        class="sub-input"
        size="large"
        :placeholder="$t('template.typeSth', {name: $t('template.pwd')})"
        v-decorator="['password', { 
          rules: [$validate.requiredFunc($t('template.sthNotEmpty', {name: $t('template.pwd')}))],
        }]"
      />
    </a-form-item>

    <a-form-item :required="false" label="确认密码">
      <a-input-password
        class="sub-input"
        size="large"
        :placeholder="$t('template.typeAgainSth', {name: $t('template.pwd')})"
        v-decorator="['repassword', { 
          rules: [$validate.requiredFunc($t('template.sthNotEmpty', {name: $t('template.pwd')})), {validator: compareToFirstPassword}], 
        }]"
      />
    </a-form-item>

    <a-button
      class="submit-btn w-100"
      type="primary"
      size="large"
      :loading="loading"
      html-type="submit"
    >重设密码</a-button>
  </a-form>
</template>

<script>
  import {LoginWaysOptions} from '@/config/options';
  import {FormSendCodeButton} from '@/components/index';

  import Mixins from './_mixin';

  export default {
    data() {
      return {
        // step: 1,
        LoginWaysOptions: LoginWaysOptions(this.$t('form.login')),
        loading: false,
        accountName: ''
      }
    },

    computed: {
      subText() {
        return this.step == 1 ? '下一步' : '重设密码'
      }
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        onValuesChange: (props, values, allValues) => {
          let accountName = allValues.phoneOrEmail;

          this.accountName = accountName;
        }
      });
    },

    mixins: [Mixins],

    components: {
      FormSendCodeButton
    },

    methods: {
      resetPsw() {
        // if (this.step === 1) {

        // }
        // this.step = 2;
      },

      handleSubmit(e) {
        e.preventDefault();

        this.form.validateFieldsAndScroll((err, values) => {
          if (err) return;

          this.submitData(values);
        });
      },

      compareToFirstPassword() {
        this.$root.$validate.confirm_pass_to_first(this.form, ...arguments);
      },

      submitData(values) {
        if (!this.transaction_id) {
          this.$message.error('请发送验证码');
          return false;
        }

        this.loading = true;

        values.transaction_id = this.transaction_id;

        if (this.isEmail) {
          values.type = '1'
          values.email = values.phoneOrEmail;
        } else {
          values.type = '2';
          values.phone = values.phoneOrEmail;
        }


        this.$axios.FindPwd(values).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success(this.$t('login.req_findpwdSuccessTips'));
            this.$emit('success')
          }

          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .findpwd {
    .sen-code-btn {
      padding: 0 !important;
      font-size: 1.4rem;
    }

    /deep/ {
      .ant-form-item-control-wrapper {
        padding-left: 0 !important;
      }
    }
    .text-right {
      color: #ddd;
    }
    .title {
      font-size: 3.2rem;
      margin-bottom: 3.2rem;
    }
    .register-line {
      width: 100%;
      margin-bottom: 2.4rem;
      border-top: 1px solid rgba(0, 0, 0, 0.06);
    }
    .reset-steps {
      margin-bottom: 4.4rem;
      color: rgba(0, 0, 0, 0.25);
      .step-iconplay {
        font-size: 1.6rem;
        margin: 0 4.7rem;
      }
      .step {
        span {
          &:last-of-type {
            margin-left: 0.8rem;
          }
        }
      }
      .active-step {
        color: #f06b03;
      }
    }

    .item-text {
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .submit-btn {
      margin-top: 0.8rem;
    }

    .regiter-text {
      margin-top: 1.6rem;
      font-size: 1.2rem;
      .grey-link {
        cursor: pointer;
        color: #f06b03;
      }
    }
  }
</style>
