var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{staticClass:"register ng-form",attrs:{"form":_vm.form,"hideRequiredMark":""},on:{"submit":_vm.handleSubmit}},[_c('h2',{staticClass:"title"},[_vm._v("账号注册")]),_c('div',{staticClass:"register-line"}),_c('a-form-item',{attrs:{"label":"用户名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'name', {
          rules: [_vm.$validate.requiredFunc(_vm.$t('template.sthNotEmpty', { name: _vm.$t('template.username') }))], 
          initialValue: '',
        }]),expression:"[ 'name', {\n          rules: [$validate.requiredFunc($t('template.sthNotEmpty', { name: $t('template.username') }))], \n          initialValue: '',\n        }]"}],staticClass:"sub-input",attrs:{"size":"large","placeholder":_vm.$t('template.typeSth', { name: _vm.$t('template.username') })}})],1),_c('a-form-item',{staticStyle:{"display":"none"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'phone_area', {initialValue: '86'}]),expression:"[ 'phone_area', {initialValue: '86'}]"}]})],1),_c('a-form-item',{staticStyle:{"display":"none"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'phone_code', {initialValue: '1000'}]),expression:"[ 'phone_code', {initialValue: '1000'}]"}]})],1),_c('a-form-item',{attrs:{"label":"账号","required":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'phoneOrEmail', {
          rules: [_vm.$validate.requiredFunc(_vm.$t('template.sthNotEmpty', { name: _vm.$t('template.phoneEmail') })), _vm.$validate.phoneOrEmail ], 
        } ]),expression:"[ 'phoneOrEmail', {\n          rules: [$validate.requiredFunc($t('template.sthNotEmpty', { name: $t('template.phoneEmail') })), $validate.phoneOrEmail,], \n        },\n      ]"}],staticClass:"sub-input",attrs:{"size":"large","placeholder":"请输入手机号/邮箱"}})],1),_c('div',{staticClass:"item-text"},[_vm._v("密码")]),_c('a-form-item',{attrs:{"required":false}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password', {
          rules: [_vm.$validate.requiredFunc(_vm.$t('template.sthNotEmpty', { name: _vm.$t('template.pwd') }))],
        } ]),expression:"['password', {\n          rules: [$validate.requiredFunc($t('template.sthNotEmpty', { name: $t('template.pwd') }))],\n        },\n      ]"}],staticClass:"sub-input",attrs:{"size":"large","placeholder":_vm.$t('template.typeSth', { name: _vm.$t('template.pwd') })}})],1),_c('div',{staticClass:"item-text"},[_vm._v("确认密码")]),_c('a-form-item',{attrs:{"required":false}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:(['repassword', {
          rules: [_vm.$validate.requiredFunc( _vm.$t('template.sthNotEmpty', { name: _vm.$t('template.pwd') }))],
        } ]),expression:"['repassword', {\n          rules: [$validate.requiredFunc( $t('template.sthNotEmpty', { name: $t('template.pwd') }))],\n        },\n      ]"}],staticClass:"sub-input",attrs:{"size":"large","placeholder":_vm.$t('template.typeSth', { name: _vm.$t('template.pwd') })}})],1),_c('div',{staticClass:"item-text"},[_vm._v("验证码")]),_c('a-form-item',{key:_vm.codeIndex,staticClass:"m-bottom-0 connect-form-item"},[_c('a-form-item',{staticStyle:{"display":"inline-block","width":"calc(100% - 106px)","margin-right":"12px"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['verify_code',{
            rules: [_vm.$validate.requiredFunc(_vm.$t('template.sthNotEmpty', { name: _vm.$t('template.code') }))],
            initialValue: '',
          } ]),expression:"['verify_code',{\n            rules: [$validate.requiredFunc($t('template.sthNotEmpty', { name: $t('template.code') }))],\n            initialValue: '',\n          },\n        ]"}],staticClass:"sub-input",attrs:{"size":"large","placeholder":_vm.$t('template.typeSth', { name: _vm.$t('template.code') })}})],1),_c('div',{style:({ display: 'inline-block', width: '94px' })},[_c('form-send-code-button',{staticClass:"sen-code-btn",attrs:{"type":"sub","size":"large","url-func":_vm.codeRequestFunc},on:{"click":_vm.onClickSendCode,"sendSuccess":_vm.onSendSuccess,"countDownFinished":_vm.onCountDownFinished}})],1)],1),_c('div',{staticClass:"m-top-base"},[_vm._v(" 已阅读并同意 "),_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){_vm.showAgreementDialog = true;}}},[_vm._v(_vm._s(_vm.$t("login.registerNegociation")))])],1),_c('a-button',{staticClass:"submit-btn w-100",attrs:{"type":"primary","size":"large","html-type":"submit","loading":_vm.loading}},[_vm._v(_vm._s(_vm.$t("login.form_registerBtn")))]),_c('div',{staticClass:"regiter-text"},[_c('span',[_vm._v("已有spark music账号，")]),_c('span',{staticClass:"grey-link",on:{"click":_vm.login}},[_vm._v("立即登录")])])],1),_c('dialog-agreement',{attrs:{"visible":_vm.showAgreementDialog},on:{"update:visible":function($event){_vm.showAgreementDialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }