<template>
  <div>
    <a-form class="register ng-form" :form="form" hideRequiredMark @submit="handleSubmit">
      <h2 class="title">账号注册</h2>
      <div class="register-line"></div>

      <a-form-item label="用户名">
        <a-input
          class="sub-input"
          size="large"
          :placeholder="$t('template.typeSth', { name: $t('template.username') })"
          v-decorator="[ 'name', {
            rules: [$validate.requiredFunc($t('template.sthNotEmpty', { name: $t('template.username') }))], 
            initialValue: '',
          }]"
        />
      </a-form-item>

      <a-form-item style="display: none;">
        <a-input v-decorator="[ 'phone_area', {initialValue: '86'}]" />
      </a-form-item>

      <a-form-item style="display: none;">
        <a-input v-decorator="[ 'phone_code', {initialValue: '1000'}]" />
      </a-form-item>

      <!-- <div class="item-text">账号</div> -->
      <!-- 邮箱登录 -->
      <!-- 手机号 -->
      <a-form-item label="账号" :required="false">
        <a-input
          class="sub-input"
          size="large"
          placeholder="请输入手机号/邮箱"
          v-decorator="[ 'phoneOrEmail', {
            rules: [$validate.requiredFunc($t('template.sthNotEmpty', { name: $t('template.phoneEmail') })), $validate.phoneOrEmail,], 
          },
        ]"
        />
      </a-form-item>

      <div class="item-text">密码</div>
      <a-form-item :required="false">
        <a-input-password
          class="sub-input"
          size="large"
          :placeholder="$t('template.typeSth', { name: $t('template.pwd') })"
          v-decorator="['password', {
            rules: [$validate.requiredFunc($t('template.sthNotEmpty', { name: $t('template.pwd') }))],
          },
        ]"
        />
      </a-form-item>

      <div class="item-text">确认密码</div>
      <a-form-item :required="false">
        <a-input-password
          class="sub-input"
          size="large"
          :placeholder="$t('template.typeSth', { name: $t('template.pwd') })"
          v-decorator="['repassword', {
            rules: [$validate.requiredFunc( $t('template.sthNotEmpty', { name: $t('template.pwd') }))],
          },
        ]"
        />
      </a-form-item>

      <div class="item-text">验证码</div>
      <a-form-item class="m-bottom-0 connect-form-item" :key="codeIndex">
        <a-form-item
          style="
          display: inline-block;
          width: calc(100% - 106px);
          margin-right: 12px;
        "
        >
          <a-input
            class="sub-input"
            size="large"
            v-decorator="['verify_code',{
              rules: [$validate.requiredFunc($t('template.sthNotEmpty', { name: $t('template.code') }))],
              initialValue: '',
            },
          ]"
            :placeholder="$t('template.typeSth', { name: $t('template.code') })"
          />
        </a-form-item>

        <div :style="{ display: 'inline-block', width: '94px' }">
          <form-send-code-button
            class="sen-code-btn"
            type="sub"
            size="large"
            :url-func="codeRequestFunc"
            @click="onClickSendCode"
            @sendSuccess="onSendSuccess"
            @countDownFinished="onCountDownFinished"
          ></form-send-code-button>
        </div>
      </a-form-item>

      <div class="m-top-base">
        已阅读并同意
        <a-button
          type="link"
          @click="showAgreementDialog = true;"
        >{{ $t("login.registerNegociation") }}</a-button>
      </div>

      <a-button
        class="submit-btn w-100"
        type="primary"
        size="large"
        html-type="submit"
        :loading="loading"
      >{{ $t("login.form_registerBtn") }}</a-button>
      <div class="regiter-text">
        <span>已有spark music账号，</span>
        <span class="grey-link" @click="login">立即登录</span>
      </div>
    </a-form>

    <dialog-agreement :visible.sync="showAgreementDialog"></dialog-agreement>
  </div>
</template>

<script>
  import DialogAgreement from './DialogAgreement.vue'


  import {LoginWaysOptions} from "@/config/options";
  import {
    // FormPhoneArea,
    FormSendCodeButton,
    // FormCountrySelect,
  } from "@/components/index";

  import Mixins from "./_mixin";

  export default {
    data() {
      return {
        LoginWaysOptions: LoginWaysOptions(this.$t("form.register")),
        loading: false,
        accountName: '',
        showAgreementDialog: false,
      };
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        onValuesChange: (props, values, allValues) => {
          let accountName = allValues.phoneOrEmail;

          this.accountName = accountName;
        }
      });
    },

    mixins: [Mixins],

    components: {
      // FormPhoneArea,
      FormSendCodeButton,
      DialogAgreement,
      // FormCountrySelect,
    },

    methods: {
      login() {
        this.$emit("clickLogin");
      },
      handleSubmit(e) {
        e.preventDefault();

        this.form.validateFieldsAndScroll((err, values) => {
          if (err) return;

          // values.name = values.phoneOrEmail;

          this.submitData(values);
        });
      },

      submitData(values) {
        if (!this.transaction_id) {
          this.$message.error('请发送验证码');
          return false;
        }

        this.loading = true;

        values.transaction_id = this.transaction_id;
        values.address_id = '0';

        if (this.isEmail) {
          values.type = '1'
          values.email = values.phoneOrEmail;
        } else {
          values.type = '2';
          values.phone = values.phoneOrEmail;
        }

        this.$axios
          .Register(values)
          .then((res) => {
            const data = res.data;

            if (data && data.code == 0) {
              this.$message.success('注册成功');
              this.$emit('success')
            }

            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .register {
    .sen-code-btn {
      padding: 0 !important;
      font-size: 14px;
    }

    /deep/ {
      .ant-form-item-control-wrapper {
        padding-left: 0 !important;
      }
    }
    .text-right {
      color: #ddd;
    }
    .title {
      font-size: 3.2rem;
      margin-bottom: 32px;
    }
    .register-line {
      width: 100%;
      margin-bottom: 18px;
      border-top: 1px solid rgba(0, 0, 0, 0.06);
    }
    .item-text {
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .submit-btn {
      margin-top: 3.2rem;
    }

    .regiter-text {
      margin-top: 16px;
      font-size: 12px;
      .grey-link {
        cursor: pointer;
        color: #f06b03;
      }
    }
  }
</style>
