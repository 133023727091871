<template>
  <a-form
    class="login-wrap"
    :form="form"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
    @submit="handleSubmit"
  >
    <!-- 账号密码登录 -->
    <template v-if="activeKey === 1">
      <a-form-item label="账号" :required="false">
        <a-input
          class="sub-input"
          size="large"
          placeholder="请输入手机号/邮箱"
          v-decorator="['email',{rules: [$validate.requiredFunc($t('template.sthNotEmpty', {name: $t('template.account')}))]}]"
        />
      </a-form-item>

      <div class="pos-relative">
        <div class="forget-password">
          <span class="grey-link" @click="handleResetPwd">{{ $t("login.forgetPwd") }}</span>
        </div>

        <a-form-item label="密码" :required="false">
          <a-input-password
            class="sub-input"
            size="large"
            :placeholder="$t('template.typeSth', { name: $t('template.pwd') })"
            v-decorator="['password',{
              rules: [$validate.requiredFunc($t('template.sthNotEmpty', { name: $t('template.pwd') }))]
            }]"
          />
        </a-form-item>
      </div>
    </template>

    <a-form-item style="display: none;">
      <a-input class="sub-input" size="large" v-decorator="[ 'phone_area', {initialValue: '86'}]" />
    </a-form-item>

    <a-form-item style="display: none;">
      <a-input v-decorator="[ 'phone_code', {initialValue: '1000'}]" />
    </a-form-item>

    <template v-if="activeKey === 2">
      <a-form-item label="手机号" :required="false">
        <a-input
          class="sub-input"
          size="large"
          :placeholder="$t('template.typeSth', { name: $t('template.phoneNum') })"
          v-decorator="['phone', {
            rules: [$validate.requiredFunc($t('template.sthNotEmpty', { name: $t('template.phoneNum') })), $validate.phone]
          }]"
        />
      </a-form-item>

      <a-form-item label="验证码" :required="false">
        <a-input
          class="sub-input"
          style="display: inline-block; width: calc(100% - 106px); margin-right: 12px;"
          size="large"
          :placeholder="$t('template.typeSth', { name: $t('template.code') })"
          v-decorator="['verify_code', {rules: [$validate.requiredFunc($t('template.sthNotEmpty', {name: $t('template.code') }))]}]"
        />
        <div :style="{display: 'inline-block', width: '94px'}">
          <form-send-code-button
            class="sen-code-btn"
            type="sub"
            size="large"
            :url-func="codeRequestFunc"
            @click="onClickSendPhoneCode"
            @sendSuccess="onSendSuccess"
            @countDownFinished="onCountDownFinished"
          ></form-send-code-button>
        </div>
      </a-form-item>
    </template>

    <a-button
      class="submit-btn submit-btn-login w-100"
      type="primary"
      size="large"
      html-type="submit"
      :disabled="btnDisabled"
      :loading="loading"
    >{{ $t("login.form_loginBtn") }}</a-button>

    <div class="regiter-text">
      <span>还没有spark music账号，</span>
      <span class="grey-link" @click="register">快速注册</span>
    </div>
  </a-form>
</template>

<script>
  import {LoginWaysOptions} from "@/config/options";
  import {FormSendCodeButton} from "@/components/index";
  import {mapMutations} from 'vuex'

  const labelCol = {span: 24};
  const wrapperCol = {span: 24};

  import Mixins from "./_mixin";

  export default {
    data() {
      return {
        LoginWaysOptions: LoginWaysOptions(this.$t("form.login")),

        labelCol,
        wrapperCol,

        loading: false,
        btnDisabled: true,
      };
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        onValuesChange: (props, values, allValues) => {
          this.$nextTick(() => {
            let allHasVal = Object.values(allValues).every(item => item);
            let allPassCheck = Object.values(props.form.getFieldsError()).every(item => !item);
            let isPass = allHasVal && allPassCheck

            this.btnDisabled = !isPass;
          })
        }
      });
    },
    props: {
      activeKey: {
        type: Number,
        default: 2,
      },
    },

    watch: {
      activeKey() {
        this.btnDisabled = true;
      }
    },

    mixins: [Mixins],

    components: {
      FormSendCodeButton
    },

    methods: {
      ...mapMutations(['changeLoginAndRegisterDialog']),
      register() {
        this.$emit('clickRegister')
      },
      handleResetPwd() {
        this.$emit('clickResetPwd')
      },
      handleSubmit(e) {
        e.preventDefault();

        this.form.validateFieldsAndScroll((err, values) => {
          if (err) return;

          this.submitData(values);
        });
      },

      submitData(values) {
        if (!this.transaction_id && this.activeKey == 2) {
          this.$message.error('请发送验证码');
          return false;
        }

        this.loading = true;

        values.type = this.activeKey;
        values.transaction_id = this.transaction_id;

        this.$axios
          .Login(values)
          .then((res) => {
            const data = res.data;

            if (data && data.code == 0) {
              this.$message.success(this.$t("login.req_loginSuccessTips"));
              this.$store.dispatch("inLogin", data.data);
              this.changeLoginAndRegisterDialog(false);
            }

            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .login-wrap {
    .sen-code-btn {
      padding: 0 !important;
      font-size: 1.4rem;
    }

    .forget-password {
      position: absolute;
      right: 0;
      z-index: 10;
    }

    .submit-btn-login {
      margin-top: 12px;
    }

    .regiter-text {
      margin-top: 16px;
      font-size: 12px;
    }

    .grey-link {
      cursor: pointer;
      color: #f06b03;
    }
  }
</style>
