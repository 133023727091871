export default {
  data() {
    return {
      loginType: this.$val.LoginWay.email,
      codeIndex: 0
    }
  },

  computed: {
    isEmail() {
      let reg = /@/;
      let acn = this.accountName;

      return reg.test(acn);
    },

    // 验证码发送方法
    codeRequestFunc() {
      return !this.isEmail ? this.$axios.SendCommonPhoneCode : this.$axios.SendCommonEmailCode;
    },

    // 绑定手机/邮箱
    bindCodeFunc() {
      return !this.isEmail ? this.$axios.Account_bindPhone : this.$axios.Account_bindEmail;
    }
  },

  methods: {
    onChangeLoginType(value) {
      this.loginType = value;
      this.codeIndex++;
    },

    onClickSendPhoneCode(next) {
      this.form.validateFields(['phone_area', 'phone'], (errors, values) => {
        if (values.phone) {
          values.sms_tpl = this.$val.SendCodeDefaultValue.phone;
          // values.phone_code = '86'
        }

        if (!errors) {
          next(values);
        }
      })
    },

    onClickSendCode(next) {
      this.form.validateFields(['phoneOrEmail', 'phone_area'], (errors, values) => {
        if (!this.isEmail || values.phone) {
          values.sms_tpl = this.$val.SendCodeDefaultValue.phone;
          values.phone = values.phoneOrEmail;
        } else {
          values.email_tpl = this.$val.SendCodeDefaultValue.email;
          values.email = values.phoneOrEmail;
        }

        if (!errors) {
          next(values);
        }
      })
    },

    onSendSuccess(data) {
      if (data && data.code == 0) {
        this.$message.success('已成功发送验证码');
        this.showSuccessTip = true;
        this.transaction_id = data.data.transaction_id;
      }
    },

    onCountDownFinished() {
      this.showSuccessTip = false;
    },
  }
}